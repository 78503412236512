@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfgCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gTaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBegCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdXxZgCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdRhYgCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/russoone/v14/Z9XUDmZRWg6M1LvRYsH-yA.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdQhfgCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjNdG7kmE0gTaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdVBegCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdXxZgCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/teko/v15/LYjCdG7kmE0gdRhYgCM.ttf) format('truetype');
}
body {
  background: #eef6fb;
  color: #333339;
  font-weight: 500;
}
.main {
  padding: 0 25%;
}
@media only screen and (max-width: 950px) {
  .main {
    padding: 0 15%;
  }
}
@media only screen and (max-width: 720px) {
  .main {
    padding: 0 10%;
  }
}
@media only screen and (max-width: 768px) {
  .main {
    padding: 0 8%;
  }
}
@media only screen and (max-width: 576px) {
  .main {
    padding: 0 5%;
  }
}
@media only screen and (max-width: 576px) {
  .main {
    margin-bottom: 60px;
  }
}
.navbar {
  padding: 35px 15px;
}
.navbar ul {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #333339;
}
.navbar li {
  font-family: "Montserrat", sans-serif;
  color: #333339;
  font-size: 26px;
  font-weight: 300;
}
.navbar li:hover {
  color: #5fb0e8;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .navbar li {
    width: 100%;
    text-align: center;
    margin: 4px 0;
  }
}
.active-item {
  font-weight: bold;
  color: #5fb0e8;
}
.active-item:hover {
  color: #5fb0e8;
}
.main-box {
  border: 5px solid white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 36px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 1px;
  background: white;
}
.boxes {
  border-radius: 5px 5px 0 0;
  padding-bottom: 10px;
  background: #eef6fb;
  margin-bottom: -5px;
}
.boxes > p {
  padding: 25px 0 15px;
  color: #333339;
}
.boxes p {
  margin: 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 300;
}
@media only screen and (max-width: 576px) {
  .boxes p {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
.small-box {
  border-radius: 5px 5px 0 0;
  padding-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  justify-content: space-between;
  margin: 10px 0 1px !important;
  padding: 0 !important;
  overflow: hidden;
  color: #333339;
}
.small-box p:first-of-type {
  margin-bottom: 5px;
}
.small-box p:last-child {
  margin: -14px 0 12px;
  font-family: "Teko", sans-serif;
  font-weight: normal;
  font-size: 36px;
}
.small-box p:last-child span {
  font-family: "Russo One", sans-serif;
  font-size: 15px;
  position: relative;
  top: 8px;
  vertical-align: top;
  left: 2px;
}
@media only screen and (max-width: 576px) {
  .small-box p:last-child {
    font-size: 48px;
  }
}
.small-box > div {
  width: calc(25% - 4px);
  background: #eef6fb;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.small-box > div p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
}
@media only screen and (max-width: 576px) {
  .small-box > div {
    width: 100% !important;
    margin: 2px 0;
  }
  .small-box > div p {
    font-size: 28px;
  }
}
.today {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -26px 0 0;
  flex-flow: row wrap;
}
.tempreture {
  display: flex;
  flex-flow: column;
  align-items: self-start;
}
@media only screen and (max-width: 576px) {
  .tempreture {
    width: 100%;
    align-items: center;
  }
}
.tempreture p:first-of-type {
  color: #333339;
  font-size: 67px;
  font-weight: 400;
  font-family: "Teko", sans-serif;
  line-height: 0.7;
}
@media only screen and (max-width: 576px) {
  .tempreture p:first-of-type {
    font-size: 48px;
    margin-bottom: 2px;
    margin-top: 10px;
  }
}
.today-temp {
  font-size: 28px;
  vertical-align: top;
  font-family: "Russo One", sans-serif;
  margin-left: 2px;
  font-weight: 600;
}
@media only screen and (max-width: 576px) {
  .today-temp {
    font-size: 15px;
    position: relative;
    top: -6px;
    vertical-align: top;
    left: 2px;
    font-weight: 500;
  }
}
#icon {
  color: #045d8d;
  stroke-width: 0.5;
  stroke: #eef6fb;
}
.today-icon {
  color: #045d8d;
  stroke-width: 0.5;
  stroke: #eef6fb;
  font-size: 150px;
}
@media only screen and (max-width: 576px) {
  .today-icon {
    font-size: 80px;
  }
}
.daily-icon {
  color: #045d8d;
  stroke-width: 0.5;
  stroke: #eef6fb;
  font-size: 60px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 576px) {
  .daily-icon {
    font-size: 80px;
  }
}
